import React from "react";
import { useFormik } from "formik";
import { useUserContext } from "../../context/UserSelectionContext";
import { Input } from "reactstrap";
import ValidateUserForms from "../Helpers/ValidateUserForm";
import useUsersService from "../../services/UsersService";
import UserDetailVehicles from "../Vehicles/UserDetailVehicles";

const UserDetail = () => {
  const { userSelected } = useUserContext();
  const { updateUserDetail, deleteUser } = useUsersService();

  const removeUser = (id) => {
    console.log("removeUser", id);
    deleteUser(id);
  };

  const formik = useFormik({
    initialValues: {
      id: userSelected?.id || "",
      firstName: userSelected?.firstName || "",
      lastName: userSelected?.lastName || "",
      identificationNumber: userSelected?.identificationNumber || "",
      role: userSelected?.role || "",
      registeredUser: userSelected?.registeredUser || false,
      tags: userSelected?.tags || [],
      vehicles: userSelected?.vehicles || [],
    },
    enableReinitialize: true,
    validate: (values) => {
      return ValidateUserForms(values);
    },
    onSubmit: (values, { setSubmitting }) => {
      console.log("onSubmit", values, formik.errors);

      if (values.tags && typeof values.tags === "string") {
        values.tags = values.tags.split(",");
      }

      updateUserDetail(values)
        .then((data) => {
          console.log("updateUserDetail", data);
          setSubmitting(false);
        })
        .catch((error) => {
          console.log("updateUserDetail.error", error);
          setSubmitting(false);
        });
      //alert(JSON.stringify(values, null, 2));
    },
  });

  if (!userSelected) {
    return <div>Seleccione un usuario</div>;
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3 row">
          <label htmlFor="firstName" className="col-sm-2 col-form-label">
            Nombre
          </label>
          <div className="col-sm-10">
            <Input
              type="input"
              className="form-control"
              id="firstName"
              placeholder="Nombre..."
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalid={formik.errors.firstName && formik.touched.firstName}
            ></Input>
          </div>
          {formik.errors.firstName &&
            formik.touched.firstName &&
            formik.errors.firstName}
        </div>
        <div className="mb-3 row">
          <label htmlFor="lastName" className="col-sm-2 col-form-label">
            Apellido
          </label>
          <div className="col-sm-10">
            <Input
              type="input"
              className="form-control"
              id="lastName"
              name="lastName"
              placeholder="Apellido..."
              value={formik.values.lastName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalid={formik.errors.lastName && formik.touched.lastName}
            />
          </div>
          {formik.errors.lastName &&
            formik.touched.lastName &&
            formik.errors.lastName}
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="identificationNumber"
            className="col-sm-2 col-form-label"
          >
            Numero Identificacion
          </label>
          <div className="col-sm-10">
            <Input
              type="input"
              className="form-control"
              id="identificationNumber"
              placeholder="Numero Identificacion..."
              value={formik.values.identificationNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalid={formik.errors.identificationNumber}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="role" className="col-sm-2 col-form-label">
            Rol
          </label>
          <div className="col-sm-10">
            <Input
              type="input"
              className="form-control"
              id="role"
              placeholder="Ej: Conductor, Empleada, Plomero, etc..."
              value={formik.values.role}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalid={formik.errors.role}
            />
          </div>
          {formik.errors.role && formik.touched.role && formik.errors.role}
        </div>

        <div className="mb-3 row">
          <label htmlFor="tags" className="col-sm-2 col-form-label">
            Etiquetas
          </label>
          <div className="col-sm-10">
            <Input
              type="input"
              className="form-control"
              id="tags"
              placeholder="Ej: Numero del apartamento o casa"
              value={formik.values.tags}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalid={formik.errors.tags}
            />
            <p className="text-muted">Separe las etiquetas con una coma (,)</p>
          </div>
          {formik.errors.tags && formik.touched.tags && formik.errors.tags}
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {userSelected?.id ? "Actualizar" : "Registrar"}
        </button>

        {userSelected?.id && (
          <button
            className="btn btn-danger ms-2"
            type="button"
            onClick={() => {
              removeUser(userSelected.id);
            }}
          >
            Eliminar
          </button>
        )}
      </form>

      <hr />
      <div className="mb-3 row">
        {userSelected?.id ? (
          <UserDetailVehicles />
        ) : (
          <div className="col-sm-10">
            <p className="text-muted">
              Para agregar un vehiculo primero debes registrar el usuario
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetail;
