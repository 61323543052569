const SortUsers = (data) => {
  data.sort((a, b) => {
    if (a.tags[0] < b.tags[0]) {
      return -1;
    }
    if (a.tags[0] > b.tags[0]) {
      return 1;
    }
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  });
  return data;
};

export default SortUsers;
