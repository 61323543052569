import { useFormik } from "formik";
import React from "react";
import { register } from "../../services/LoginService";
import { Alert, Button, Col, Input, Label, NavLink, Row } from "reactstrap";
import "./Register.scss";
import useLoadingService from "../../services/LoadingService";
import ParseErrorCodes from "../Helpers/RegisterErrorCodes";

const Register = () => {
  const { ShowLoading, HideLoading } = useLoadingService();
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Requerido";
      }
      if (!values.email) {
        errors.email = "Requerido";
      }
      //validate email format
      if (!values.email.includes("@")) {
        errors.email = "Formato de correo incorrecto";
      }

      if (!values.password) {
        errors.password = "Requerido";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Requerido";
      }
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Las contraseñas no coinciden";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      console.log("onSubmit.values", values);
      setSubmitting(true);
      setSubmitting(false);

      ShowLoading();
      setErrorMessages([]);
      setSuccessMessage("");

      register(values.name, values.email, values.password)
        .then((data) => {
          HideLoading();

          console.log("data", data);

          if (typeof data === "string" && data === "ok") {
            setErrorMessages("");
            setSuccessMessage(
              "Usuario registrado correctamente. Seras redirigido al inicio de sesión en 5 segundos"
            );
            setTimeout(() => {
              window.location.href = "/";
            }, 5000);

            return;
          }

          if (Array.isArray(data)) {
            const errors = ParseErrorCodes(data);
            setErrorMessages(errors);
            return;
          }
        })
        .catch((error) => {
          console.log("error", error);
          HideLoading();
        });
      setSubmitting(false);
    },
  });

  return (
    <Row>
      <Col
        className="bg-light border"
        md={{
          offset: 3,
          size: 6,
        }}
        sm="12"
      >
        <h1 className="text-xl font-semibold">¡Crea una cuenta!</h1>
        <form className="mt-4" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col mb-3 mt-3">
            <Label htmlFor="name">Nombre Completo</Label>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              id="name"
              placeholder="Ramiro Bedoya"
              required
              type="text"
              invalid={formik.errors.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="register-invalid-input">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="flex flex-col mb-3 mt-3">
            <Label htmlFor="email">Correo Electrónico</Label>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              id="email"
              placeholder="m@example.com"
              required
              type="email"
              invalid={formik.errors.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="register-invalid-input">
                {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col mb-3 mt-3">
            <Label htmlFor="password">Contraseña</Label>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              id="password"
              required
              type="password"
              invalid={formik.errors.password && formik.touched.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="register-invalid-input">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col mb-3 mt-3">
            <Label htmlFor="confirmPassword">Confirmar Contraseña</Label>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              id="confirmPassword"
              required
              type="password"
              invalid={
                formik.errors.confirmPassword && formik.touched.confirmPassword
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="register-invalid-input">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>

          {errorMessages && errorMessages.length > 0 && (
            <Alert color="danger">
              {errorMessages.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </Alert>
          )}
          {successMessage && <Alert>{successMessage}</Alert>}

          <Button
            className="w-full py-2 rounded-xl bg-indigo-600 text-gray-100 focus:outline-none"
            disabled={!formik.isValid}
          >
            Registrarse
          </Button>
        </form>
        <NavLink className="text-indigo-500 text-sm text-center mt-4" href="#">
          ¿Olvidaste tu contraseña?
        </NavLink>
        <NavLink
          className="text-indigo-500 text-sm text-center mt-2 mb-3"
          href="#"
        >
          Volver a la página de inicio
        </NavLink>
      </Col>
    </Row>
  );
};

export default Register;
