import { useLoadingContext } from "../context/LoadingContext";

const useLoadingService = () => {
  const { setLoading } = useLoadingContext();

  const ShowLoading = () => {
    setLoading(true);
  };

  const HideLoading = () => {
    setLoading(false);
  };

  return { ShowLoading, HideLoading };
};

export default useLoadingService;
