const ParseErrorCodes = (data) => {
  let errors = [];
  data.forEach((error) => {
    switch (error.code) {
      case "PasswordTooShort":
        errors.push(`La contraseña debe tener al menos 6 caracteres `);
        break;
      case "PasswordRequiresNonAlphanumeric":
        errors.push(
          `La contraseña debe tener al menos un caracter especial (+,-,*, etc)`
        );
        break;
      case "PasswordRequiresLower":
        errors.push(
          `La contraseña debe tener al menos una letra minúscula (a - z)`
        );
        break;
      case "PasswordRequiresUpper":
        errors.push(
          `La contraseña debe tener al menos una letra mayúscula (A - Z) `
        );
        break;
      case "DuplicateUserName":
        errors.push(`El correo electrónico ya está registrado `);
        break;
      default:
        errors.push(`${error.code} : ${error.description}`);
        break;
    }
  });
  return errors;
};

export default ParseErrorCodes;
