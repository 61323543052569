import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./UserDetailVehicles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faBicycle,
  faCar,
  faMotorcycle,
  faTruck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Form, Input } from "reactstrap";
import useVehicleService from "../../services/Vehicleservice";
import VehicleDetail from "./VehicleDetail";
import { useUserContext } from "../../context/UserSelectionContext";
import { toast } from "react-toastify";
import useUsersService from "../../services/UsersService";

const UserDetailVehicles = () => {
  const [rSelected, setRSelected] = useState(null);
  const [addVehicleToggle, setAddVehicleToggle] = useState(false);
  const { userSelected } = useUserContext();
  const { getUsers } = useUsersService();
  //set setRSelected to false everytime userSelected change
  useEffect(() => {
    setAddVehicleToggle(false);
  }, [userSelected]);

  //useVehicleService
  const AddVehicle = useVehicleService();

  const validate = (values) => {
    const errors = {};
    if (!values.color) {
      errors.color = "Requerido";
    }
    if (!rSelected) {
      errors.type = "Selecciona algun vehiculo";
    }
    if (!values.plateNumber) {
      errors.plateNumber = "Requerido";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      color: "", //data.color,
      id: "", //data.id,
      plateNumber: "", //data.plateNumber,
      type: 0, //,
    },
    validate,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("onSubmit.values", values);
      const newVehicle = {
        ...values,
        UserId: userSelected.id,
        type: rSelected,
      };
      const response = await toast.promise(
        AddVehicle(newVehicle),
        {
          pending: "Agregando vehiculo...",
          success: "Vehiculo agregado correctamente",
          error: "Error al agregar vehiculo",
        },
        {
          style: {
            width: "auto",
          },
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        }
      );
      console.log("response", response);
      setSubmitting(false);
      setAddVehicleToggle(false);
      formik.resetForm();

      //Refresh users
      getUsers().then((data) => {
        console.log("getUsers", data);
      });
    },
  });

  return (
    <div className="user-detail-vehicle-container">
      <VehicleDetail vehicles={userSelected.vehicles} />
      <hr />

      <Button onClick={() => setAddVehicleToggle(!addVehicleToggle)}>
        {!addVehicleToggle ? (
          <FontAwesomeIcon size="lg" icon={faAdd} />
        ) : (
          <FontAwesomeIcon size="lg" icon={faXmark} />
        )}

        {userSelected.vehicles.length === 0
          ? addVehicleToggle
            ? "  Cerrar"
            : "  Agregar Vehiculo"
          : null}
      </Button>

      {addVehicleToggle && (
        <Form onSubmit={formik.handleSubmit}>
          <div className="multi-select-vehicle">
            <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={() => setRSelected(1)}
                active={rSelected === 1}
              >
                <FontAwesomeIcon size="2x" icon={faCar} />
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => setRSelected(2)}
                active={rSelected === 2}
              >
                <FontAwesomeIcon size="2x" icon={faMotorcycle} />
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => setRSelected(3)}
                active={rSelected === 3}
              >
                <FontAwesomeIcon size="2x" icon={faBicycle} />
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => setRSelected(4)}
                active={rSelected === 4}
              >
                <FontAwesomeIcon size="2x" icon={faTruck} />
              </Button>
            </ButtonGroup>
          </div>

          <div className="invalid-input">
            {formik.errors.type && formik.touched.type && formik.errors.type}
          </div>

          <div className="mb-3 row">
            <label htmlFor="color" className="col-sm-4 col-form-label">
              Color
            </label>
            <div className="col-sm-8">
              <Input
                type="text"
                className="form-control"
                name="color"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.color}
                invalid={formik.errors.color && formik.touched.color}
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="plateNumber" className="col-sm-4 col-form-label">
              Matrícula
            </label>
            <div className="col-sm-8">
              <Input
                type="text"
                className="form-control"
                name="plateNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.plateNumber}
                invalid={
                  formik.errors.plateNumber && formik.touched.plateNumber
                }
              />
            </div>
          </div>
          <div>
            <input
              type="text"
              name="id"
              value={formik.values.id}
              hidden={true}
              readOnly={true}
            />
            <input
              type="text"
              name="type"
              value={formik.values.type}
              hidden={true}
              readOnly
            />
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary mb-3"
          >
            Agregar vehiculo
          </button>
        </Form>
      )}
    </div>
  );
};

export default UserDetailVehicles;
