import { Row } from "reactstrap";
import { logout } from "../../services/LoginService";
import useLoadingService from "../../services/LoadingService";
import { useEffect } from "react";
import { useUserContext } from "../../context/UserSelectionContext";

export default function Logout() {
  const { ShowLoading, HideLoading } = useLoadingService();
  const { setCurrentSession } = useUserContext();

  useEffect(() => {
    ShowLoading();
    logout().then((data) => {
      console.log("logout", data);
      setCurrentSession(null);
      //Redirect to home
      HideLoading();
      window.location.href = "/";
    });
  }, [ShowLoading, HideLoading, setCurrentSession]);

  return (
    <Row className="flex flex-col h-screen bg-gray-100">
      <p>Cerrando sesión</p>
    </Row>
  );
}
