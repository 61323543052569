const ValidateUserForms = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Requerido";
  }
  if (!values.lastName) {
    errors.lastName = "Requerido";
  }
  if (!values.identificationNumber) {
    errors.identificationNumber = "Requerido";
  }
  if (!values.role) {
    errors.role = "Requerido";
  }
  if (!values.tags) {
    errors.tags = "Requerido";
  }
  return errors;
};

export default ValidateUserForms;
