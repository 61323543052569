import React from "react";
import { Container } from "reactstrap";
import { useLoadingContext } from "../context/LoadingContext";
import "./Layout.scss";
import NavMenu from "./NavMenu";

const Layout = (props) => {
  const { loading } = useLoadingContext();

  return (
    <div>
      {loading && <div className="loading" />}

      <NavMenu />
      <Container tag="main">{props.children}</Container>
    </div>
  );
};

export default Layout;
