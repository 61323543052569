import { Label, Input, Button, NavLink, Row, Col, Alert } from "reactstrap";
import { login } from "../../services/LoginService";
import useLoadingService from "../../services/LoadingService";
import { useState } from "react";
import { useUserContext } from "../../context/UserSelectionContext";

export default function Login() {
  const { ShowLoading, HideLoading } = useLoadingService();
  const { setCurrentSession } = useUserContext();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    ShowLoading();

    login(e.target.email.value, e.target.password.value)
      .then((data) => {
        HideLoading();

        console.log("data", data);
        if (data.accessToken) {
          setCurrentSession(data);
          return;
        }

        if (data.status === 401 && data.detail === "LockedOut") {
          setErrorMessage("Usuario bloqueado, contacte al administrador");
          return;
        }
        setErrorMessage("Usuario o contraseña incorrectos");
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Error intentando iniciar sesión");
        HideLoading();
      });
  };

  return (
    <Row className="flex flex-col h-screen bg-gray-100">
      <Col
        className="bg-light border"
        md={{
          offset: 3,
          size: 6,
        }}
        sm="12"
      >
        <h1 className="text-xl font-semibold">Bienvenido de nuevo!</h1>
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="flex flex-col mb-2 mt-2">
            <Label htmlFor="email">Correo Electrónico</Label>
            <Input
              id="email"
              placeholder="m@example.com"
              required
              type="email"
            />
          </div>
          <div className="flex flex-col mb-2 mt-2">
            <Label htmlFor="password">Contraseña</Label>
            <Input id="password" required type="password" />
          </div>
          <Button
            className="w-full py-2 rounded-xl bg-indigo-600 text-gray-100 focus:outline-none"
            type="submit"
          >
            Iniciar sesión
          </Button>
        </form>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <NavLink className="text-indigo-500 text-sm text-center mt-6" href="#">
          ¿Olvidaste tu contraseña?
        </NavLink>
        <NavLink
          className="text-indigo-500 text-sm text-center mt-2"
          href="/Register"
        >
          ¡Crea una cuenta!
        </NavLink>
      </Col>
    </Row>
  );
}
