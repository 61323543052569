//Load all users from the API and get the JWT token from useUserContext();
// const { currentSession } = useUserContext();
// currentSession.accessToken;
import { useUserContext } from "../context/UserSelectionContext";
import { useCallback } from "react";
import { refreshToken } from "./LoginService";
import { config } from "../config";
import { toast } from "react-toastify";
import SortUsers from "../components/Helpers/SortUsers";

const useUsersService = () => {
  const {
    currentSession,
    setCurrentSession,
    setUsers,
    userSelected,
    setUserSelected,
  } = useUserContext();

  const getUsers = useCallback(
    async (newToken) => {
      const url = `${config.API_URL}/api/Entities`;
      console.log("API_URL", url);

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${newToken ?? currentSession.accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(SortUsers(data));

        //check if new user is created
        const newUserSelected = localStorage.getItem("newUserSelected");
        if (newUserSelected) {
          const newUser = JSON.parse(newUserSelected);
          const user = data.find(
            (u) => u.identificationNumber === newUser.identificationNumber
          );

          setUserSelected(user);
          localStorage.removeItem("newUserSelected");
        } else if (userSelected) {
          const user = data.find((u) => u.id === userSelected.id);
          setUserSelected(user);
        }
      } else if (response.status === 401) {
        //Refresh token
        await refreshToken(currentSession.refreshToken).then((data) => {
          setCurrentSession(data);

          return getUsers(data.accessToken);
        });
      }
    },
    // eslint-disable-next-line
    [currentSession, setCurrentSession, setUserSelected, setUsers]
  );

  const updateUserDetail = useCallback(
    async (user) => {
      const promise = new Promise((resolve, reject) => {
        const url = `${config.API_URL}/api/Entities`;
        console.log("API_URL", url);

        fetch(url, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${currentSession.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        })
          .then(async (response) => {
            if (response.ok) {
              localStorage.removeItem("newUserSelected");
              localStorage.setItem("newUserSelected", JSON.stringify(user));
              setUserSelected(null);

              getUsers();
              resolve(response);
            } else if (response.status === 401) {
              //Refresh token
              await refreshToken(currentSession.refreshToken).then((data) => {
                setCurrentSession(data);

                return updateUserDetail(user);
              });
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });

      toast.promise(
        promise,
        {
          pending: "Actualizando usuario...",
          success: "Usuario actualizado correctamente",
          error: "Error al actualizar usuario",
        },
        {
          style: {
            width: "auto",
          },
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        }
      );
    },
    // eslint-disable-next-line
    [currentSession, setCurrentSession, setUserSelected, setUsers, userSelected]
  );

  const deleteUser = useCallback(
    async (userId) => {
      const promise = new Promise((resolve, reject) => {
        const url = `${config.API_URL}/api/Entities/${userId}`;
        console.log("API_URL", url);

        fetch(url, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${currentSession.accessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.ok) {
              setUserSelected(null);
              getUsers();
              resolve(response);
            } else if (response.status === 401) {
              //Refresh token
              await refreshToken(currentSession.refreshToken).then((data) => {
                setCurrentSession(data);

                return deleteUser(userId);
              });
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });

      toast.promise(
        promise,
        {
          pending: "Eliminando usuario...",
          success: "Usuario eliminado correctamente",
          error: "Error al actualizar usuario",
        },
        {
          style: {
            width: "auto",
          },
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        }
      );
    },

    // eslint-disable-next-line
    [currentSession, setCurrentSession, setUserSelected, setUsers, userSelected]
  );
  return { getUsers, updateUserDetail, deleteUser };
};

export default useUsersService;
