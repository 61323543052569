import { useCallback } from "react";
import { useUserContext } from "../context/UserSelectionContext";
import { config } from "../config";

const useVehicleService = () => {
  const { currentSession } = useUserContext();

  const AddVehicle = useCallback(
    async (vehicle) => {
      const url = `${config.API_URL}/api/entities/add-vehicle`;
      console.log("API_URL", url);

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(vehicle),

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentSession.accessToken}`,
        },
      });

      if (response.ok) {
        return "Vehiculo agregado correctamente";
      }
    },
    [currentSession]
  );
  return AddVehicle;
};
export default useVehicleService;
