import React, { createContext, useMemo, useState } from "react";

export const LoadingContext = createContext();

export function LoadingContextProvider(props) {
  const [loading, setLoading] = useState(false);

  const value = useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [loading]
  );

  return (
    <LoadingContext.Provider value={value}>
      {props.children}
    </LoadingContext.Provider>
  );
}

export function useLoadingContext() {
  const context = React.useContext(LoadingContext);
  if (!context) {
    throw new Error(
      "useUserContext must be used within a LoadingContextProvider"
    );
  }
  return context;
}
