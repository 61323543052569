import React from "react";
import Dashboard from "./Dashboard";
import { useUserContext } from "../context/UserSelectionContext";
import Login from "./Auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Home = () => {
  const { currentSession } = useUserContext();

  if (!currentSession) {
    return <Login />;
  }
  return (
    <div>
      <ToastContainer autoClose={3000} />

      <Dashboard />
    </div>
  );
};

export default Home;
