import { config } from "../config";

//Get the JWT Token from the server
export const login = async (email, password) => {
  const url = `${config.API_URL}/login`;
  console.log("API_URL", url);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();
  //Set LocalStorage
  localStorage.clear();
  if (data.accessToken) {
    localStorage.setItem("token", JSON.stringify(data));
  }
  return data;
};

//Get user info from the server using JWT Token
export const getUserInfo = async () => {
  var currentSession = JSON.parse(localStorage.getItem("token"));
  if (!currentSession) return null;

  const url = `${config.API_URL}/api/RegisteredUsers`;
  console.log("API_URL", url);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentSession.accessToken}`,
    },
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return null;
};

//Get the JWT Token from the server
export const register = async (names, email, password) => {
  const url = `${config.API_URL}/api/account/register`;
  console.log("API_URL", url);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ names, email, password }),
  });

  if (response.ok) {
    return "ok";
  } else if (response.status === 400) {
    const data = await response.json();
    return data;
  }
  return response;
};

export const logout = async () => {
  var currentSession = JSON.parse(localStorage.getItem("token"));
  console.log("logout", currentSession);
  if (!currentSession) return null;

  const url = `${config.API_URL}/api/account/logout`;
  console.log("API_URL", url);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentSession.accessToken}`,
    },
    body: JSON.stringify({}),
  });

  if (response.ok) {
    localStorage.clear();
  }
  return response;
};

export const refreshToken = async (refreshToken) => {
  const url = `${config.API_URL}/refresh`;
  console.log("API_URL", url);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refreshToken: refreshToken,
    }),
  });

  const data = await response.json();
  //Set LocalStorage
  localStorage.clear();
  if (data?.accessToken) {
    localStorage.setItem("token", JSON.stringify(data));
  }
  return data;
};
