import React, { createContext, useEffect, useMemo, useState } from "react";

export const UserContext = createContext();

export function UserContextProvider(props) {
  const [users, setUsers] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("token"));
    setCurrentSession(session);
  }, []);

  const value = useMemo(
    () => ({
      users,
      setUsers,
      userSelected,
      setUserSelected,
      currentSession,
      setCurrentSession,
      search,
      setSearch,
    }),
    [users, userSelected, currentSession, search]
  );

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

export function useUserContext() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
}
