import {
  faBicycle,
  faCar,
  faMotorcycle,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const VehicleTypes = ({ vehicleType, size = "3x" }) => {
  return (
    <div>
      {vehicleType === "Car" && (
        <div>
          <FontAwesomeIcon size={size} icon={faCar} />
        </div>
      )}
      {vehicleType === "Motorcycle" && (
        <div>
          <FontAwesomeIcon size={size} icon={faMotorcycle} />
        </div>
      )}
      {vehicleType === "Bicycle" && (
        <div>
          <FontAwesomeIcon size={size} icon={faBicycle} />
        </div>
      )}
      {vehicleType === "Truck" && (
        <div>
          <FontAwesomeIcon size={size} icon={faTruck} />
        </div>
      )}
    </div>
  );
};

export default VehicleTypes;
