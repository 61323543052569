import React from "react";
import VehicleTypes from "./VehicleTypes";
import "./VehicleDetail.scss";

const VehicleDetail = ({ vehicles, size }) => {
  if (size === "xs") {
    return (
      <div className="vehicle-container-xs">
        {vehicles.map((vehicle) => (
          <div key={vehicle.id} className="existing-vehicle-xs">
            <VehicleTypes vehicleType={vehicle.type} size="sm" />
            <div className="vehicle-details">
              <div>{vehicle.plateNumber}</div>
              <div>{vehicle.color}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="vehicle-container">
      {vehicles.map((vehicle) => (
        <div key={vehicle.id} className="existing-vehicle">
          <VehicleTypes vehicleType={vehicle.type} />
          <div className="vehicle-details">
            <div>Color: {vehicle.color}</div>
            <div>Matrícula: {vehicle.plateNumber}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VehicleDetail;
