import React, { useEffect } from "react";
import { useUserContext } from "../../context/UserSelectionContext";
import useUsersService from "../../services/UsersService";
import VehicleDetail from "../Vehicles/VehicleDetail";
import { toast } from "react-toastify";

const Users = () => {
  const { users, userSelected, setUserSelected, search } = useUserContext();
  const { getUsers } = useUsersService();

  useEffect(() => {
    async function fetchData() {
      await toast.promise(
        getUsers(),
        {
          pending: "Cargando usuarios...",
          success: "Usuarios cargados correctamente",
          error: "Error al cargar usuarios",
        },
        {
          style: {
            width: "auto",
          },
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        }
      );
    }
    fetchData();
  }, [getUsers]);

  const userSelectedHandler = (user) => {
    if (userSelected && userSelected.id === user.id) {
      setUserSelected(null);
      return;
    }
    setUserSelected(user);
  };

  var filteredUsers = users?.filter((user) => {
    return (
      user.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.identificationNumber.toLowerCase().indexOf(search.toLowerCase()) !==
        -1 ||
      user.role.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.tags?.some(
        (tag) => tag.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ) ||
      user.vehicles?.some(
        (vehicle) =>
          vehicle.color.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          vehicle.plateNumber.toLowerCase().indexOf(search.toLowerCase()) !==
            -1 ||
          vehicle.type.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    );
  });

  return (
    <div>
      <ol className="list-group">
        {filteredUsers?.map((user) => (
          <li
            key={user.id}
            className="list-group-item d-flex justify-content-between align-items-start"
            style={{
              backgroundColor:
                userSelected && userSelected.id === user.id
                  ? "lightgray"
                  : "white",
            }}
            onClick={() => userSelectedHandler(user)}
          >
            <div className="ms-2 me-auto d-flex row justify-content-between align-items-start">
              <div className="fw-bold">
                {user.firstName}
                {` `}
                {user.lastName}
              </div>
              <div className="ms-2 me-auto">
                <strong>CC:</strong> {user.identificationNumber}
              </div>
              <div className="ms-2 me-auto">
                <strong>Rol:</strong> {user.role}
              </div>
              {user.vehicles?.length > 0 && (
                <div className="ms-2 me-auto">
                  <VehicleDetail vehicles={user.vehicles} size={"xs"} />
                </div>
              )}
            </div>
            {user.tags?.length > 0 &&
              user.tags.map((tag, index) => (
                <div key={index} className="badge bg-primary rounded-pill ml-2">
                  {tag}
                </div>
              ))}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Users;
