import Logout from "./components/Auth/Logout";
import Register from "./components/Auth/Register";
import Home from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/Register",
    element: <Register />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },

  /* {
    path: "/counter",
    element: <Counter />,
  },
  {
    path: "/fetch-data",
    element: <FetchData />,
  }, */
];

export default AppRoutes;
