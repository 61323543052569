import React, { useEffect } from "react";
import Users from "./Users/Users";
import UserDetail from "./Users/UserDetail";
import { getUserInfo } from "../services/LoginService";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../context/UserSelectionContext";
import "./Dashboard.scss";

const Dashboard = () => {
  const [userName, setUserName] = React.useState([]);
  const { setUserSelected, setSearch } = useUserContext();

  useEffect(() => {
    getUserInfo().then((data) => {
      if (data) {
        setUserName(data.names);
      }
    });
  }, []);

  const search = (event) => {
    console.log("search", event.target.value);
    setSearch(event.target.value);
  };

  const addNewUser = () => {
    if (setUserSelected) {
      setUserSelected(null);
    }
    const newUser = {
      firstName: "",
      lastName: "",
      identificationNumber: "",
      role: "",
      tags: [],
      vehicles: [],
    };
    setUserSelected(newUser);
  };

  return (
    <div>
      <div className="d-flex align-content-stretch">
        <h1 className="title">Hola {userName}</h1>
        <div className="ms-auto">
          <InputGroup>
            <Input placeholder="Buscar ..." onKeyUp={(e) => search(e)} />
            <InputGroupText>
              <FontAwesomeIcon size="2x" icon={faSearch} />
            </InputGroupText>
          </InputGroup>
        </div>
        <div className="ms-auto">
          <Button color="primary" outline onClick={() => addNewUser(1)}>
            <FontAwesomeIcon size="2x" icon={faUserPlus} />
          </Button>
        </div>
      </div>
      <hr />
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-5 users-container">
            <Users />
          </div>
          <div className="col-sm-7">
            <UserDetail />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
